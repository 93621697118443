import { FC, useCallback, useEffect, useRef, useState } from "react"

type ExtendedProps<Result = unknown, Data = unknown> = {
  resolve: (val?: Result) => void; onCancel: () => void; initialData: Data; open: boolean
 }

export type AwaitableModal<Result = unknown, Data = unknown> = FC<ExtendedProps<Result, Data>>

const useAwaitableModal:
<Result = unknown, Data = unknown>
(Modal: AwaitableModal<Result, Data>, initialData: Data, dontRerenderOnInitialChange?: boolean)
=> [(data?: Data) => Promise<Result>, boolean | JSX.Element]
= (Modal, initialData, dontRerenderOnInitialChange = false) => {
  const [requesting, setRequesting] = useState(false)
  const resolve = useRef<((val: any) => void) | undefined>()
  const reject = useRef<(() => void) | undefined>()
  const [data, setData] = useState(initialData)
  const [changingData, setChangingData] = useState(false)

  const request = useCallback(async(data?: typeof initialData) => {
    if (requesting) return
    if (data) setData(data)
    const newPromise = new Promise<any>((_resolve, _reject) => {
      resolve.current = _resolve
      reject.current = _reject
    }).finally(() => {
      setRequesting(false)
      resolve.current = undefined
      resolve.current = undefined
    })
    setRequesting(true)

    return newPromise
  }, [requesting])

  useEffect(() => {
    setChangingData(true)
  }, [data])

  useEffect(() => {
    if (!changingData) return
    setChangingData(false)
  }, [changingData])

  return [request, (!changingData || dontRerenderOnInitialChange) && <Modal
    open={requesting}
    resolve={(val?: any) => resolve.current?.(val as any)}
    onCancel={() => reject.current?.()}
    initialData={data}
  />]
}

export default useAwaitableModal
