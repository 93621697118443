import { FC, useEffect, useRef, useState } from "react"

const Scrollbar: FC = () => {
  const ref = useRef<HTMLDivElement | null>(null)
  const parentRef = useRef<HTMLElement | null>(null)
  const [scrollbarHeight, setScrollbarHeight] = useState<number>(0)
  const [scrollbarTop, setScrollbarTop] = useState<number>(0)
  const [hidden, setHidden] = useState<boolean>(false)
  const [parentHeight, setParentHeight] = useState<number>(0)

  useEffect(() => {
    if (!ref.current || !parentRef.current) {
      return
    }

    parentRef.current.classList.add("group", "custom-scrollbar", "relative")

    const handler = () => {
      const temp = parentRef.current
      if (!temp) return
      setHidden(temp.scrollHeight <= temp.clientHeight)
      setParentHeight(temp.clientHeight)
      const { scrollTop, scrollHeight, clientHeight } = temp!
      const barHeight = (clientHeight / scrollHeight) * clientHeight
      const progress = scrollTop / (scrollHeight - clientHeight)
      const barTop = scrollTop + (scrollHeight - clientHeight > 0 ? progress * (clientHeight - barHeight) : 0)
      setScrollbarTop(barTop)
      setScrollbarHeight(barHeight)
    }

    parentRef.current.addEventListener("scroll", handler, { passive: true })

    return () => {
      parentRef.current?.removeEventListener("scroll", handler)
    }
  }, [parentRef, scrollbarHeight])

  return (
    <div
      ref={e => {
        ref.current = e
        parentRef.current = e?.parentElement || null
      }}
      style={{ height: scrollbarHeight, transform: `translateY(${scrollbarTop}px)` }}
      className={["bg-slate-500 w-2 top-0 rounded-full opacity-0 group-hover:opacity-100",
        "right-1 absolute overflow-hidden",
        hidden && "hidden"].asClass}
    />
  )
}

export default Scrollbar
