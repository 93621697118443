import { ICraigPayload } from "api/types"
import { FC } from "react"

interface props {
    payload: ICraigPayload;
}

const Payload: FC<props> = ({ payload }) => {
  if (payload.type === "file") {
    return (
      <div
        className="bg-slate-50 px-4 py-2 rounded-xl group cursor-pointer hover:bg-slate-100 hover:underline transition-colors"
        onClick={() => window.open(payload.url, "_blank")}
      >
        {payload.name}
      </div>
    )
  }

  return null
}

export default Payload
