/* eslint-disable no-extend-native */
import React from "react"
import ReactDOM from "react-dom/client"
import "./index.css"
import Root from "./Root"
import reportWebVitals from "./reportWebVitals"
import AuthProvider from "contexts/Auth/AuthProvider"

declare global {
    interface Array<T> {
        asClass: string;
    }
}

Object.defineProperty(Array.prototype, "asClass", {
  configurable: true,
  get() {
    return this.filter(Boolean).join(" ")
  },
})

const root = ReactDOM.createRoot(document.getElementById("root") as HTMLElement)
// eslint-disable-next-line function-paren-newline
root.render(
  <React.StrictMode>
    <AuthProvider>
      <div className="hidden text-amber-500 text-lime-500 text-red-500" />
      <Root />
    </AuthProvider>
  </React.StrictMode>)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
