import { FC, PropsWithChildren } from "react"
import { Tooltip as MaterialTooltip } from "@material-tailwind/react"
import { placement } from "@material-tailwind/react/types/components/menu"

interface props {
    text: string
    position: placement
    className?: string
    withoutPointer?: boolean
}

const Tooltip: FC<PropsWithChildren<props>> = ({ children, text, position, className, withoutPointer = false }) => {
  return (
    <MaterialTooltip
      content={text}
      hidden={!text}
      className={[
        "bg-slate-900 p-4 rounded-xl max-w-[384px] text-white text-xs",
        className,
      ].asClass}
      placement={position}
      animate={{
        mount: { scale: 1 },
        unmount: { scale: 0 },
      }}
    >
      {withoutPointer ? children : (
        <div className="cursor-pointer">
          {children}
        </div>
      )}
    </MaterialTooltip>
  )
}

export default Tooltip
