import { ICraigMessage } from "api/types"
import { FC } from "react"

interface props {
    message: ICraigMessage;
}

const UserBubble: FC<props> = ({ message }) => {
  return (
    <div
      className="flex items-center w-full justify-end pl-8"
    >
      <span className="text-base font-normal relative group bg-slate-900 rounded-2xl text-white p-4">
        {message.message}
        <span
          className={[
            "opacity-0 group-hover:opacity-100 transition-all",
            "w-max absolute bottom-0 text-slate-950 group-hover:translate-y-[150%] text-xs",
            message.user ? "right-0" : "left-0",
          ].asClass}
        >
          {new Date(message.createdAt).toLocaleString()}
        </span>
      </span>
    </div>
  )
}

export default UserBubble
