import { FC } from "react"
import { createPortal } from "react-dom"

interface props {
  open: boolean
  close: (...args: any) => void
}

const Modal: FC<React.PropsWithChildren<props>> = ({ children, open, close }) => {
  return createPortal(
    <div
      className={[
        "fixed inset-0 bg-[rgba(0,0,0,0.48)] w-full h-full p-8 flex items-center justify-center z-[100000000]",
        open ? "pointer-events-auto" : "pointer-events-none [&_*]:hidden",
      ].asClass}
      style={{ transition: "opacity 0.2s ease-in-out", opacity: open ? 1 : 0 }}
      onClick={() => close()}
    >
      <div onClick={e => e.stopPropagation()}>{children}</div>
    </div>,
    document.getElementById("modals")!,
  )
}

export default Modal
